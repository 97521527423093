import React, { useState, useEffect } from "react";

//Mui Component
import {
  Card,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Box,
  useTheme,
  Tooltip,
  Skeleton,
  CircularProgress,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
//Mui Icons

import placeholder from "../../../assets/Category/placeholder.png";
//video Import from Assets Folder

import { useLocation } from "react-router-dom";
import { accpetTransfer } from "../../../services/redux/MyNftApiReducer/MyNftApiReducer";
import { setText } from "../../../services/redux/TextReducer/TextReducer";

const ClaimPackCard = ({
  nft,
  isFeatured = false,
  borderColor = "#AF22C8",
  customButton,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isClaiming, setisClaiming] = useState(false);
  const [selectedPack, setselectedPack] = useState(null);

  const { acceptTransferRes } = useSelector((state) => state.mynft);

  useEffect(() => {
    if (acceptTransferRes?.data && !acceptTransferRes?.error) {
      setisClaiming(false);
    }
  }, [acceptTransferRes.loading]);

  const handleTransferAccpet = () => {
    setselectedPack(nft);
    setisClaiming(true);

    dispatch(setText("Waiting xaman approval ..."));
    const apiData = {
      offer_id: nft?.offerId,
      nft_id: nft?.nftDbId,
    };
    dispatch(accpetTransfer({ accept_data: [apiData] }));
  };

  useEffect(() => {
    // Simulate loading time (you would replace this with actual data fetching)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Reduce time for testing, adjust as needed

    return () => clearTimeout(timer);
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  //Component States
  const [loading1, setLoading1] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [ModelImage, setModelImage] = useState(null);
  const [sellForValue, setSellForValue] = useState("");
  const dispatch = useDispatch();
  const { nftDetails } = useSelector((state) => state.nft);
  const { userId, token: isAuthenticated } = useSelector(
    (state) => state.userNft
  );

  const maxWidth = 345;

  const [visible, setVisible] = useState(false);
  const [animationStyle, setAnimationStyle] = useState({});
  const [offerValue, setOfferValue] = useState("");
  const [nftDataOffer, setNftDataOffer] = useState([]);
  const [nftData, setNftData] = useState([]);
  const [nftLike, setNftLike] = useState(
    nft?.is_liked || location.pathname.includes("nfts/favorite")
  );
  const [nftCount, setNftCount] = useState(nft?.like_count);

  const handleCardClick = () => {
    if (!nft?.minted || isClaiming || !nft?.offerId) {
    } else {
      handleTransferAccpet();
    }
    // Logs the entire nft object to the console
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          maxWidth: maxWidth,
          minWidth: 100,
          bgcolor: isFeatured
            ? "background: linear-gradient(45deg, #d4d4d4, #a6a6a6)"
            : "black",
          border: `1px solid ${borderColor}`,

          borderRadius: "10px",
          p: isFeatured ? 0 : 0.5,
        }}
      >
        <Card
          sx={{
            position: "relative",
            background: isFeatured
              ? "radial-gradient(ellipse farthest-corner at right , #ffffff 0%, #d1d1d1 10%, #A8A9AD 20%, #f0f0f0 70%, #636060 100%)"
              : "black",

            borderRadius: isFeatured ? "10px" : 0,
          }}
        >
          <Box
            sx={{
              px: 0.5,
              pt: 0.5,
              display: "block",
              width: "100%",
              minHeight: "250px", // Default height
              borderRadius: "15px",
              overflow: "hidden",
              cursor: "pointer",
              [theme.breakpoints.down("md")]: {
                height: "220px",
              },
            }}
          >
            {(loading || !imageLoaded) && (
              <Skeleton
                variant="rectangular"
                animation="pulse" // Change to 'pulse' for a simple fade animation
                width="300px"
                height="400px"
                sx={{
                  borderRadius: "10px",
                  bgcolor: "#9e9e9e", // Set the background color to gray
                }}
              />
            )}

            <CardMedia
              component="img"
              sx={{
                display: loading || !imageLoaded ? "none" : "block",
                px: 0.5,
                pt: 0.5,
                width: "100%",
                height: "auto",
                borderRadius: "10px",
                objectPosition: "center",
                objectFit: "contain",
                [theme.breakpoints.down("md")]: {
                  height: "240px",
                },
              }}
              onLoad={handleImageLoad}
              image={nft?.nftImage ? nft?.nftImage : placeholder}
              alt={nft?.metadata?.name}
            />
          </Box>

          {/* CARD SECTION */}
          <CardContent sx={{ p: 1 }}>
            <Typography
              gutterBottom
              variant="p"
              component="p"
              sx={{
                p: 0,
                m: 0,
                color: "#AF22C8",
                display: "flex",
                justifyContent: "space-between",
                fontFamily: "goodTime",
                fontSize: "11px", // Default font size
                [theme.breakpoints.down("md")]: {
                  fontSize: "10px", // Font size on medium and smaller devices
                },
                [theme.breakpoints.down("sm")]: {
                  fontSize: "9px", // Font size on small and smaller devices
                },
              }}
            >
              NFT ID
            </Typography>

            {/* TIITLE 2 */}

            <Typography
              gutterBottom
              variant="p"
              component="p"
              sx={{
                textAlign: "start",
                fontSize: "12px", // Default font size
                [theme.breakpoints.down("md")]: {
                  fontSize: "10px", // Font size on medium and smaller devices
                },
                [theme.breakpoints.down("sm")]: {
                  fontSize: "11px", // Font size on small and smaller devices
                },
                p: 0,
                m: 0,
                color: isFeatured ? "black" : "white",
                fontFamily: "goodTime",
              }}
            >
              <Tooltip title={nft?.nftId}>
                {nft?.nftId
                  ? nft?.nftId.length > 10
                    ? `${nft?.nftId?.slice(0, 20)}...`
                    : nft?.nftId
                  : "N/A"}
              </Tooltip>
            </Typography>

            {customButton ? (
              customButton
            ) : (
              <Button
                variant="contained"
                size="small"
                onClick={handleCardClick}
                sx={{
                  paddingY: "5px",
                  marginY: "10px",
                  bgcolor: !nft?.minted || !nft?.offerId ? "#AF22C8" : "white",
                  color: "black",
                  border: "2px solid #AF22C8",
                  width: "100%",
                  textTransform: "none",
                  fontFamily: "goodTime",
                  "&:hover": {
                    bgcolor: "#AF22C8",
                    color: "white",
                    "& .MuiSvgIcon-root": { color: "white" },
                  },
                }}
              >
                {" "}
                {selectedPack === nft && isClaiming ? (
                  <CircularProgress sx={{ color: "purple" }} size={"25px"} />
                ) : !nft?.minted ? (
                  "minting..."
                ) : !nft?.offerId ? (
                  "transferring..."
                ) : (
                  "Claim Now"
                )}
              </Button>
            )}
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default ClaimPackCard;
