import React from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomLoader from "../../shared/customLoader/customLoader";
import ClaimPackCard from "../Campaigns/ClaimPackCard";

function EvmPacksModal({ metadata, loading, onClose, openModal, txHash }) {
  return (
    <div>
      <Modal
        componentsProps={{
          backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.8)" } },
        }}
        open={openModal}
        onClose={onClose}
      >
        {loading ? (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              zIndex: 1000,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "80vh",
              }}
            >
              <CustomLoader width={"60%"} text={"Fetching cards data ... "} />
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              direction: "column",
              alignItems: "center",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              flexDirection: "column",
            }}
          >
            <Paper
              elevation={2}
              sx={{
                bgcolor: "black",
                border: "solid 2px #af22c8",
                borderRadius: 4,
                color: "white",
                p: { xs: 1, sm: 2, md: 4, lg: 4 },
                width: { xs: "90vw", sm: "70vw", md: "60vw", lg: "40vw" },
                textAlign: "center",
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ py: 1 }}
              >
                <Box />
                <Box
                  justifyContent={"end"}
                  sx={{
                    mt: { xs: -1, sm: -2, md: -4, lg: -4 },
                    mr: { xs: 1, sm: 0, md: -2, lg: -2 },
                  }}
                >
                  <IconButton
                    sx={{
                      bgcolor: "none",
                      color: "white",
                      border: "2px solid #af22c8",
                      borderRadius: "8px",
                      padding: "2px",
                    }}
                    onClick={onClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>

              <Typography sx={{ fontFamily: "goodTime", fontSize: 14, mb: 4 }}>
                🎉 Cards Minted! 🎉
              </Typography>
              {metadata?.length ? (
                <Grid
                  container
                  spacing={2}
                  sx={{
                    paddingInline: 2,
                    maxHeight: "60vh",
                    overflowY: "auto",
                    scrollbarColor: "#AF22C8 white",
                    scrollbarWidth: "thin",
                  }}
                  justifyContent={metadata < 4 ? "center" : "start"}
                >
                  {metadata.length &&
                    metadata?.map((item) => (
                      <Grid
                        item
                        lg={4}
                        sm={6}
                        xs={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                        key={item.id}
                      >
                        <ClaimPackCard
                          nft={{ nftImage: item.image, nftId: item.id }}
                          borderColor="#AF22C8"
                          customButton={<div></div>}
                        />
                      </Grid>
                    ))}
                </Grid>
              ) : (
                <Typography
                  sx={{ fontFamily: "goodTime", fontSize: 14, mb: 4 }}
                >
                  Error fetching metadata
                </Typography>
              )}

              <Button
                href={`https://arbiscan.io/tx/${txHash}`}
                target="_blank"
                sx={{
                  backgroundColor: "#AF22C8",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#AF22C8",
                  },
                  borderRadius: "20px",
                  textTransform: "none",
                  m: { xs: 1, sm: 2 },
                  px: 2,
                }}
              >
                <Typography sx={{ fontFamily: "goodTime" }}>
                  Open transaction
                </Typography>
              </Button>
            </Paper>
          </Box>
        )}
      </Modal>
    </div>
  );
}

export default EvmPacksModal;
