import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Logo from "../../../../../../../assets/logos/DeLuxMintLogo.png";
import { Typography, Menu, MenuItem, Stack, Switch } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { TextField, Grid, IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import CloseIcon from "@mui/icons-material/Close";

import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet"; // Example icon for NFT Results

import CollectionsIcon from "@mui/icons-material/Collections"; // Icon for Collection Results

import MediaCard from "../../../../../../features/CategorryCard/Card";

import { axiosRequestAuth } from "../../../../../../../services/api/api";

import { useNavigate } from "react-router-dom";

import SearchCard from "../../../../../../shared/searchCard/searchCard";
import CustomLoader from "../../../../../../shared/customLoader/customLoader";
import { EvmConnectButton } from "../../../../../../features/Evm/EvmConnectButton";

const drawerStyle = {
  backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the background color to black
  color: "white", // Set the text color to white
  transition: "background-color 0.3s",
  paper: {
    background: "black",
    color: "white",
  },
};

const submenuStyle = {
  overflow: "hidden",
  transition: "max-height 0.5s ease-in-out", // Adjust animation speed here
  maxHeight: "0",
};

function EvmDrawer() {
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    left: false,
  });
  // const [activeTab, setActiveTab] = React.useState(0);

  // const handleTabClick = (index) => {
  //   setActiveTab(index);
  //   // Handle tab click logic here
  // };
  const [packsAnchorEl, setPacksAnchorEl] = useState(null);

  const [nfts, setNfts] = useState([]);
  const [collection, setCollection] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [query, setQuery] = useState();

  const toggleDrawer = (anchor, open) => (event) => {
    console.log("Event: ", event);

    // Check if the event is provided and meets the condition
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handlePacksClick = (event) => {
    setPacksAnchorEl(event.currentTarget);
  };

  const handlePacksClose = () => {
    setPacksAnchorEl(null);
  };

  const handleSwitchChange = () => {
    navigate("/");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Call your function here
      GetSearch();
      setOpenModal(true);
    }
  };

  async function GetSearch() {
    setLoading(true);

    try {
      const response = await axiosRequestAuth("post", "market/search", {
        searchQuery: query,
      });
      setNfts(response?.data?.data?.nfts);

      setCollection(response?.data?.data?.collections);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      //  toast.error(error.response.data.message);
    }
  }

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  const handleClose = (e) => {
    setOpenModal(false);
  };

  const handleData = (e) => {
    setOpenModal(false);
    // useDispatch(setCollectionId())
    navigate(`/category/${e.id}`);
  };

  const packMenuItems = [
    {
      label: "Gladiator Decks",
      navigate: "/arb/packs/Gladiator%20Deck",
    },
    {
      label: "Pirate Decks",
      navigate: "/arb/packs/Pirate%20Deck",
    },
    {
      label: "Druid Decks",
      navigate: "/arb/packs/Druid%20Deck",
    },
    {
      label: "Avartars",
      navigate: "/arb/packs/Avartars",
    },
    {
      label: "Cardbacks",
      navigate: "/arb/packs/Cardbacks",
    },
  ];

  const handlePackClick = (navigateTo) => {
    navigate(navigateTo);
    handlePacksClose();
  };

  return (
    <>
      <Box>
        <Box
          className="ff"
          sx={{ height: "4.5rem", display: "flex", alignItems: "center" }}
        >
          <Button onClick={toggleDrawer("left", true)}>
            <MenuIcon sx={{ color: "white" }} />
          </Button>
        </Box>

        <Drawer
          // style={{ }}
          PaperProps={{
            sx: {
              width: "250px", // Adjust this value to your desired width
              background: "rgba(0,0,0,100)",
              color: "white",
              // height: "100vh",
            },
          }}
          anchor="left"
          open={state.left}
          onClose={toggleDrawer("left", false)}
          sx={drawerStyle}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
              src={Logo}
              height={85}
              className="pointer-on-hover"
              style={{ paddingBottom: 6, paddingTop: 10 }}
              onClick={() => {
                navigate("/");
                toggleDrawer("left", false)(); // Close the drawer
              }}
              alt="logo"
            />
          </Box>

          <Box sx={{ pt: 2, px: 2, pb: 2 }}>
            <TextField
              key={openModal}
              style={{ width: "100%" }}
              size="small"
              placeholder="Search"
              onKeyPress={handleKeyPress}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#D7D1D1" }} />
                  </InputAdornment>
                ),
                sx: {
                  color: "#D7D1D1",
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  borderRadius: 6,
                  "& .MuiOutlinedInput-notchedOutline": {},
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: " 2px solid #7F6C6C",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: " 2px solid #7F6C6C",
                  },
                  height: "35px",
                },
              }}
              autoComplete="off" // Add this line to turn off auto-fill
            />
          </Box>

          <Box sx={{ px: 2, pb: 2 }}>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
                bgcolor: "rgba(255, 255, 255, 0.2)",
                borderRadius: 2,
                px: 1,
                justifyContent: "center",
              }}
            >
              <Typography>XRP</Typography>
              <Switch
                checked={true}
                onChange={handleSwitchChange}
                color="white"
              />
              <Typography>ARB</Typography>
            </Stack>
          </Box>

          <Box>
            <Button
              disableRipple
              class="custom-button"
              onClick={() => {
                toggleDrawer("left", false)(); // Close the drawer
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                Explorer
              </Typography>
            </Button>
          </Box>

          <Box>
            <Button
              disableRipple
              class="custom-button"
              onClick={handlePacksClick}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                Packs
                <ArrowDropDownIcon className="icon" />
              </Typography>
            </Button>

            <Menu
              disableRipple
              className="menu"
              anchorEl={packsAnchorEl}
              open={Boolean(packsAnchorEl)}
              onClose={handlePacksClose}
            >
              {packMenuItems.map((item) => (
                <MenuItem
                  onClick={() => handlePackClick(item.navigate)}
                  sx={{ px: 6, py: 2 }}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            className="submenu"
            style={{
              ...submenuStyle,
            }}
          >
            {/* <List>
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/submenu1">
                  <ListItemText primary="Submenu Item 1" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/submenu2">
                  <ListItemText primary="Submenu Item 2" />
                </ListItemButton>
              </ListItem>
            </List> */}
          </Box>

          {/* This Box will position the Login component at the bottom center */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end", // Push content to the bottom
              alignItems: "center", // Center content horizontally
              height: "100%", // Take full height of the Drawer
              pb: 2, // Optional padding at the bottom
            }}
          >
            <EvmConnectButton />
          </Box>
        </Drawer>
      </Box>

      <Dialog
        open={openModal}
        onClose={handleClose}
        fullWidth
        maxWidth="md" // Increase the maxWidth for a wider dialog
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "#1a1a1a", // Dark black for the body background
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            backgroundColor: "#333",
            color: "white",
            display: "flex",
            justifyContent: "space-between", // Space between the items
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ fontSize: "1.6rem" }}>
            Search
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 10,
              color: "white",
              backgroundColor: "#AF22C8",
              border: "3px solid white",
              borderRadius: "50%",
              // Adjust icon size by changing the fontSize
              fontSize: "1rem", // Smaller icon size
            }}
          >
            <CloseIcon sx={{ fontSize: "inherit" }} />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers sx={{ color: "white" }}>
          {loading ? (
            <CustomLoader width={"60%"} text={"Please wait ..."} />
          ) : (
            <>
              {/* LOOKING FOR */}
              {(collection && collection.length > 0) ||
              (nfts && nfts.length > 0) ? (
                <>
                  <Typography
                    gutterBottom
                    variant="h5"
                    sx={{ color: "yellow" }}
                  >
                    What are you looking for?
                  </Typography>
                  <Typography gutterBottom>
                    Find your favorite collections and NFTs using our search
                    tool. A fuzzy search will be carried out - For example, if
                    you searched for 'xdu', your search would bring back results
                    for collections and NFTs whose name or decription contain
                    XDU - For example, xDudes
                  </Typography>
                </>
              ) : null}
              {/* LOOKING FOR */}

              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1.8rem", // Increased font size
                  mt: 3,
                  color: "#AF22C8",
                }}
              >
                <AccountBalanceWalletIcon
                  sx={{ mr: 1, color: "inherit", fontSize: "inherit" }}
                />{" "}
                NFT Results
              </Typography>
              <Typography
                gutterBottom
                sx={{ fontSize: "1.25rem", color: "#AF22C8" }}
              >
                <Grid container spacing={2}>
                  {!nfts || nfts.length === 0 ? (
                    <Box
                      sx={{
                        pt: 6,
                        p: 2,
                      }}
                    >
                      <Typography variant="h6">No NFT's Found!</Typography>
                    </Box>
                  ) : (
                    nfts?.map((items) => (
                      <Grid
                        item
                        xl={3}
                        lg={3}
                        sm={6}
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                        key={items.id}
                      >
                        <SearchCard
                          data={items}
                          forSale={false}
                          isFeatured={false}
                        />
                      </Grid>
                    ))
                  )}
                </Grid>
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1.8rem", // Increased font size
                  mt: 3,
                  color: "#AF22C8",
                }}
              >
                <CollectionsIcon
                  sx={{ mr: 1, color: "inherit", fontSize: "inherit" }}
                />{" "}
                Collection Results
              </Typography>
              <Typography
                gutterBottom
                sx={{ fontSize: "1.25rem", color: "#AF22C8" }}
              >
                <Grid container spacing={2} sx={{ display: "flex" }}>
                  {!collection || collection.length === 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        pt: 6,
                        p: 2,
                      }}
                    >
                      <Typography variant="h6">No Collection Found!</Typography>
                    </Box>
                  ) : (
                    <Grid
                      container
                      spacing={2}
                      sx={{ mt: 2, paddingInline: 4, justifyContent: "center" }}
                    >
                      <MediaCard data={collection} onConfirm={handleData} />
                    </Grid>
                  )}
                </Grid>
              </Typography>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default EvmDrawer;
