import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Marquee from "./Marquee";
import "./EvmCarousel.css";
import { Box, Typography, Skeleton, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArbPacks } from "../../../../../utils/arb/arb";

const EvmSimpleSlider = () => {
  const sliderRef = useRef(null);
  const navigate = useNavigate();

  let skeletonArray = [1, 2, 3, 4, 5, 6];

  const isBelow1824px = useMediaQuery("(max-width: 1824px)");

  const carouselNftData = ArbPacks;

  const handleClick = (data) => {
    // Handle edit action for the selected user
    navigate(`/arb/packs/${data.name}`);
  };
  let settings = {
    dots: false,
    infinite: true,
    focusOnSelect: true,
    lazyLoad: true,
    speed: 1000, // Adjust the autoplay speed in milliseconds (3 seconds in this case)
    autoplay: true, // Enable autoplay
    slidesToShow: 5,
    draggable: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 3840,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1980,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {!carouselNftData.loading ? (
        <Box
          sx={{
            pt: 2,
            mt: -2,
            pb: 2,
            position: "relative", // Needed for the drop shadow
          }}
        >
          <Box sx={{ textAlign: "center", mb: 2, paddingBlock: 2 }}>
            <Typography
              // variant={isBelow1824px ? "h5" : "h4"}
              variant="h4"
              sx={{
                color: "white",
                fontWeight: "bold",
                fontFamily: "goodTime",
                // fontSize: isBelow1024px ? 18 : isBelow1824px ? 24 : 30,
                fontSize: { xs: 18, lg: 24 },
              }}
            >
              Explore, sell and collect on the MultiChain Universe
            </Typography>
          </Box>
          <div
            style={{ paddingInline: 4, position: "relative", paddingTop: 2 }}
          >
            {settings && (
              <Slider {...settings} ref={sliderRef}>
                {carouselNftData?.length &&
                  carouselNftData?.map((item, i) => (
                    <Box
                      key={i}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                        objectFit: "contain",
                      }}
                      onClick={() => handleClick(item)}
                    >
                      <img
                        src={item.thumbnail}
                        alt={item.name}
                        style={{
                          // minHeight: isBelow724px ? "100px" : "250px", // Set a maximum height
                          // maxHeight: isBelow724px ? "300px" : "550px",
                          width: "100%", // Ensure the image takes the full width of the container
                          paddingInline: isBelow1824px ? "2px" : "4px",
                        }}
                      />
                    </Box>
                  ))}
              </Slider>
            )}
          </div>
        </Box>
      ) : (
        <Box
          sx={{
            pt: 2,
            mt: -2,
            pb: 2,
            position: "relative", // Needed for the drop shadow
          }}
        >
          <Box sx={{ textAlign: "center", mb: 2, paddingBlock: 2 }}>
            <Typography
              variant="h4"
              sx={{
                color: "white",
                fontWeight: "bold",
                fontFamily: "goodTime",
                fontSize: { xs: 18, lg: 24 },
              }}
            >
              {"Explore, sell and collect on the MultiChain Universe"}
            </Typography>
          </Box>
          <div
            style={{ paddingInline: 4, position: "relative", paddingTop: 2 }}
          >
            {settings && (
              <Slider {...settings} ref={sliderRef}>
                {skeletonArray.map((item) => (
                  <Box
                    key={item.id}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                      objectFit: "contain",
                      pl: 0.5,
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={300}
                      sx={{ bgcolor: "grey.900", paddingInline: "6px" }}
                    />
                  </Box>
                ))}
              </Slider>
            )}
          </div>
        </Box>
      )}
    </>
  );
};

export default EvmSimpleSlider;
