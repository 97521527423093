import React from "react";
import { Box, Grid, Typography, Container } from "@mui/material";
import MediaCard from "../../../features/CategorryCard/Card";
import { useNavigate } from "react-router-dom";
import { ArbPacks } from "../../../../utils/arb/arb";

const EvmPack = () => {
  const txtcolor = "#f1f1f1";
  const navigate = useNavigate();

  const handleData = (e) => {
    /* dispatch(setCategoryId(e.id)); */
    let name = e?.name?.replace(" ", "");
    console.log(name, "name");
    navigate(`/arb/packs/${e.name}`);
  };

  const orderedRows = ArbPacks;

  return (
    <Box>
      <Container maxWidth={false}>
        <Grid container spacing={4}></Grid>
      </Container>

      <Box sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
        <Typography
          variant="h5"
          sx={{
            color: txtcolor,
            fontWeight: 600,
            fontSize: 28,
            fontFamily: "goodTime",
            justifyContent: "center",
          }}
        >
          Buy Packs
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item lg={2}>
          {" "}
          <Box
            sx={{ display: "flex", justifyContent: "start", pt: 3, ml: 6 }}
          ></Box>
        </Grid>
        <Grid item lg={8}></Grid>
        <Grid item lg={2}>
          <Box sx={{ display: "flex", justifyContent: "center", pt: 4 }}>
            <Box sx={{ pt: 4, pr: 2 }}></Box>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        // spacing={4}
        sx={{
          // px: "10%",

          maxWidth: "1200px",
          mx: "auto",
        }}
      >
        {orderedRows &&
          orderedRows?.map((items, index) => (
            <Grid
              item
              lg={4}
              sm={12}
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
              key={index}
            >
              <MediaCard data={items} local onConfirm={handleData} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default EvmPack;
