import { Routes, Route } from "react-router-dom";
import MyNFTs from "../pages/myNFTs/myNFTs";
import React from "react";
import Home from "../pages/home/home";
import SingleMinting from "../pages/singleMinting/singleMinting";
import ForSale from "../pages/ForSale/forSale";
import CreateCategories from "../pages/categories/categories";
import CategoriesForm from "../components/features/Admins/CategoriesForm/CategoriesForm";
import CollectionForm from "../components/features/Admins/CollectionForm/CollectionForm";
import AdminCategories from "../pages/admins/adminCategories/adminCategories";
import Admins from "../pages/admins/admins/admins";
import AdminCollections from "../pages/admins/adminCollections/adminCollections";
import AdminFeatured from "../pages/admins/adminFeatured/adminFeatured";
import AdminCarousel from "../pages/admins/adminCarousel/adminCarousel";
import Collection from "../pages/collections/collections";
import AllNFTs from "../pages/collections/allNfts/allNfts";
import CollectionMint from "../components/features/Admins/MintType/MintType";
import BulkMinting from "../pages/bulkMinting/bulkMinting ";
import AllCollection from "../pages/allCollections/allCollections";
import BulkMintDetail from "../pages/admins/adminBulkMintDetail/adminBulkMintDetail";
import PrivateRoute from "./privateRoute";
import NotFound from "../pages/notFound/NotFound";
import AdminRoute from "./adminRoute";
import About from "../pages/about/About";
import TransferNFTs from "../pages/transferNFTs/transferNFTs";
import UserCollectionNFTs from "../pages/userCollectionNFT/UserCollectionNFT";
import UsdCollection from "../pages/usdCollections/usdCollections";
import UsdCollectionDetails from "../pages/usdCollectionDetails/usdCollectionDetails";
import USDCollectionNFTs from "../pages/USDCollectionNFTs/USDCollectionNFTs";
import PrivacyPolicy from "../pages/privacyPolicy/privacyPolicy";
import Terms from "../pages/terms/terms";
import BulkTrasnferForm from "../components/features/Admins/BulkTransfer/BulkTransfer";
import BulkTransferDetails from "../pages/admins/bulkTransfer/BulkTrasferDetails";
import EditCollectionForm from "../components/features/Admins/EditCollectionForm/EditCollectionForm";
import CollectionAllNfts from "../pages/collections/collectionAllNfts/collectionAllNfts";
import AddUser from "../components/features/Admins/AddUser/AddUser";
import AddWalletForm from "../components/features/Admins/AddUser/AddWalletForm";
// import FreeMints from "../pages/freeMints/freeMints";
import UserHistory from "../components/features/Admins/UserHistory/UserHistory";
import AddMints from "../components/features/Admins/AddUser/AddMints";
import GameCollection from "../pages/gameCollections/gameCollections";
import GameCollectionDetail from "../pages/gameCollectionDetail/GameCollectionDetail";
import SyncNfts from "../pages/syncNfts/SyncNfts";
import Campaigns from "../pages/campaigns/campaigns";
import RewardBags from "../pages/campaigns/rewardbags";
import EvmHome from "../pages/evm/home/evmHome";
import EvmPacks from "../pages/evm/packs/evmPacks";
import EvmPackDetail from "../pages/evm/pack/evmPackDetail";

function AllRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/recently-for-sale" element={<ForSale />} />
        <Route path="/xrp-collections" element={<AllCollection />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        {/* <Route path="/free-mint" element={<FreeMints />} /> */}
        <Route
          path="/usd-collections/details/:id"
          element={<UsdCollectionDetails />}
        />
        <Route path="/usd-collections/nfts" element={<USDCollectionNFTs />} />
        <Route path="/usd-collections" element={<UsdCollection />} />
        <Route path="/game-collections" element={<GameCollection />} />
        <Route
          path="/game-collections/details/:id"
          element={<GameCollectionDetail />}
        />

        <Route
          path="/category-collection/:collections"
          element={<CollectionAllNfts />}
        />
        <Route path="/collection/:accountNumber/:name" element={<AllNFTs />} />

        <Route path="/category/:name" element={<Collection />} />
        <Route path="/category/collections" element={<AllNFTs />} />
        <Route path="/packs" element={<Campaigns />} />

        <Route
          path="/nfts/:type"
          element={
            <PrivateRoute>
              <MyNFTs />
            </PrivateRoute>
          }
        />
        <Route
          path="/breeding/rewardbag/:id"
          element={
            <PrivateRoute>
              <RewardBags />
            </PrivateRoute>
          }
        />
        <Route
          path="/nfts/transfers"
          element={
            <PrivateRoute>
              <TransferNFTs />
            </PrivateRoute>
          }
        />

        <Route
          path="/nfts/collection-nfts"
          element={
            <PrivateRoute>
              <UserCollectionNFTs />
            </PrivateRoute>
          }
        />

        <Route
          path="/categories"
          element={
            <PrivateRoute>
              <CreateCategories />
            </PrivateRoute>
          }
        />

        {/* Admin's Route */}
        <Route
          path="/minting-single"
          element={
            <AdminRoute>
              <SingleMinting />
            </AdminRoute>
          }
        />
        <Route
          path="/minting-bulk"
          element={
            <AdminRoute>
              <BulkMinting />
            </AdminRoute>
          }
        />
        <Route
          path="/admins"
          element={
            <AdminRoute>
              <Admins />
            </AdminRoute>
          }
        />
        <Route
          path="/user-mints"
          element={
            <AdminRoute>
              <AddUser />
            </AdminRoute>
          }
        />
        <Route
          path="/mint-history"
          element={
            <AdminRoute>
              <UserHistory />
            </AdminRoute>
          }
        />
        <Route
          path="/bulk-add-wallet"
          element={
            <AdminRoute>
              <AddWalletForm />
            </AdminRoute>
          }
        />
        <Route
          path="/bulk-add-mints"
          element={
            <AdminRoute>
              <AddMints />
            </AdminRoute>
          }
        />
        <Route
          path="/collections-mint"
          element={
            <AdminRoute>
              <CollectionMint />
            </AdminRoute>
          }
        />
        <Route
          path="/bulk-transfer"
          element={
            <AdminRoute>
              <BulkTrasnferForm />
            </AdminRoute>
          }
        />
        <Route
          path="/bulk-transfer-details"
          element={
            <AdminRoute>
              <BulkTransferDetails />
            </AdminRoute>
          }
        />
        <Route
          path="/sync-nfts"
          element={
            <AdminRoute>
              <SyncNfts />
            </AdminRoute>
          }
        />
        <Route
          path="/bulk-mint-detail"
          element={
            <AdminRoute>
              <BulkMintDetail />
            </AdminRoute>
          }
        />
        <Route
          path="/collections"
          element={
            <AdminRoute>
              <AdminCollections />
            </AdminRoute>
          }
        />
        <Route
          path="/admin/featured"
          element={
            <AdminRoute>
              <AdminFeatured />
            </AdminRoute>
          }
        />
        <Route
          path="/admin/categories"
          element={
            <AdminRoute>
              <AdminCategories />
            </AdminRoute>
          }
        />
        <Route
          path="/admin/carousel"
          element={
            <AdminRoute>
              <AdminCarousel />
            </AdminRoute>
          }
        />
        <Route
          path="/category-add"
          element={
            <AdminRoute>
              <CategoriesForm />
            </AdminRoute>
          }
        />
        <Route
          path="/collection-add"
          element={
            <AdminRoute>
              <CollectionForm />
            </AdminRoute>
          }
        />

        <Route
          path="/admin/collection-edit/:id"
          element={
            <AdminRoute>
              <EditCollectionForm />
            </AdminRoute>
          }
        />
        <Route
          path="/admin/categories/:id"
          element={
            <AdminRoute>
              <CategoriesForm />
            </AdminRoute>
          }
        />

        <Route path="/arb" element={<EvmHome />} />
        <Route path="/arb/packs/:name" element={<EvmPacks />} />
        <Route path="/arb/pack/detail/:id" element={<EvmPackDetail />} />

        {/* Not Found Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default AllRoutes;
