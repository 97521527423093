import React from "react";
import styles from "./evmHome.module.css";
import Banners from "../../../components/layout/Home/Banners/Banners";
import SpotLight from "../../../components/layout/Home/SpotLight/SpotLight";
import BottomBanner from "../../../components/layout/Home/Banners/BottomBanner/BottomBanner";
import EvmPack from "../../../components/layout/Evm/Pack/EvmPack";
import EvmSimpleSlider from "../../../components/layout/Evm/Home/Carousel/EvmCarousel";

function EvmHome() {
  return (
    <div className={styles.home}>
      <EvmSimpleSlider />
      <Banners />
      <SpotLight />
      <EvmPack />
      <BottomBanner />
    </div>
  );
}

export default EvmHome;
