import { ConnectButton } from "thirdweb/react";
import { client } from "../../../client";
import { arbitrum } from "thirdweb/chains";
import { createWallet, inAppWallet } from "thirdweb/wallets";

export function EvmConnectButton() {
  const wallets = [
    inAppWallet({
      auth: {
        options: ["google", "telegram", "discord", "facebook", "x"],
      },
    }),
    createWallet("io.metamask"),
    createWallet("com.coinbase.wallet"),
    createWallet("me.rainbow"),
  ];
  return (
    <div className="connect-container">
      <ConnectButton client={client} chain={arbitrum} wallets={wallets} />
    </div>
  );
}
