import axios from "axios";

export let baseURL = process.env.REACT_APP_BASE_URL;
export const imageURL = process.env.REACT_APP_IMAGE_BASE_URL;
export let socketBaseURL = process.env.REACT_APP_IMAGE_SOCKET_URL;
export const address = "rJLi6GYqWUbJky9zE28feusdHnaCwA22GT";
// export let baseURL = "http://192.168.100.101:4000/api/"; // Uzair MacBook
// export const imageURL = "http://192.168.100.101:4000/"; // Uzair MacBook
// export let socketBaseURL = "http://192.168.100.101:4001/"; // Uzair MacBook

// export let baseURL = "https://luxlabb.thecbt.live/api/"; // Umer MacBook
// export const imageURL = "https://luxlabb.thecbt.live/"; // Umer MacBook
// export let socketBaseURL = "https://luxlabc.thecbt.live/"; // Umer MacBook

export const campaignsBaseUrl = "https://mintbackend.luxlionsnft.com/api/";
export const imgUrl = "https://labbackend.luxlionsnft.com/rewardBagBackground/";
export const imgUrlLogo = "https://labbackend.luxlionsnft.com/rewardBagLogo/";

// export let baseURL = "http://192.168.100.30:4000/api/"; // Umer MacBook
// export const imageURL = "http://192.168.100.30:4000/"; // Umer MacBook
// export let socketBaseURL = "http://192.168.100.30:4001/"; // Umer MacBook

// export const campaignsBaseUrl = "http://192.168.100.30:4000/api/";
// export const imgUrl = "http://192.168.100.30:3005/rewardBagBackground/";
// export const imgUrlLogo = "http://192.168.100.30:3005/rewardBagLogo/";

// let baseURL = "http://192.168.100.89:4000/api/";
// export const imageURL = "http://192.168.100.89:4000/";

// let baseURL = "http://192.168.100.36:4000/api/"; //  My PC IP
// export const imageURL = "http://192.168.100.36:4000/"; //  My PC IP

// let baseURL = "http://localhost:4000/api/"; // Local Host
// export const imageURL = "http://localhost:4000/"; // Local Host

export let basePackURL = process.env.REACT_APP_BASE_PACK_URL;
export const openseaURL = process.env.REACT_APP_OPENSEA_BASE_URL;

export const axiosRequest = async (method_, url_, body, params_) => {
  const URL = baseURL + url_;
  const response = await axios({
    method: method_,
    url: URL,
    data: body,
    params: params_,
  });

  return response;
};

export const axiosRequestAuth = async (method_, url_, body, params_) => {
  const token = localStorage.getItem("token");
  const URL = baseURL + url_;

  const response = await axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: method_,
    url: URL,
    data: body,
    params: params_,
  });

  return response;
};

export const axiosPackRequest = async (method_, url_, body, params_) => {
  const URL = basePackURL + url_;
  const response = await axios({
    method: method_,
    url: URL,
    data: body,
    params: params_,
  });

  return response;
};
