import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Divider, Button } from "@mui/material";

// import styles from "./Mint.module.css";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  setCollectionId,
  setTaxonId,
  setCollectionName,
} from "../../../services/redux/CollectionReducer/CollectionReducer";
import CustomLoader from "../../../components/shared/customLoader/customLoader";
import UserCollectionCard from "../../../components/features/UserCollectionCard/UserCollectionCard";
import ScrollToTopOnMount from "../../../utils/ScrollToTop/automaticScrollToTop/automaticScrollToTop";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ArbPacks } from "../../../utils/arb/arb";

const containerStyle = {
  position: "relative",
  width: "100%",
  minHeight: "100vh",
  background:
    "linear-gradient(225deg, #731686 1%, transparent 10%, transparent 80%, #af22c8 100%)", // Linear gradient background
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  paddingBottom: "20px",
  overflowX: "hidden",
};
const contentStyle = {
  position: "relative",
  top: "0px",
  width: "100%",
};

const EvmPacks = () => {
  const txtcolor = "#f1f1f1";
  const [collectionData, setCollectionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const param = useParams();

  const handleData = (e) => {
    dispatch(setCollectionId(e.id));
    dispatch(setCollectionName(e.name));
    dispatch(setTaxonId(e.taxon_id));

    navigate(`/arb/pack/detail/${e.id}`);
  };
  useEffect(() => {
    const getCategoriesById = async () => {
      setLoading(true);
      try {
        const data = ArbPacks.find((pack) => pack.name === param.name);
        setLoading(false);
        setCollectionData(data?.collections);
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    };
    getCategoriesById();
  }, [param.name]);

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <Box>
          <ScrollToTopOnMount />
          <Grid container spacing={2}>
            <Grid item lg={2}>
              {" "}
              <Box
                sx={{ display: "flex", justifyContent: "start", pt: 3, ml: 1 }}
              >
                <Button
                  size="large"
                  sx={{ color: txtcolor }}
                  onClick={() => navigate("/arb")}
                >
                  <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
                </Button>
              </Box>{" "}
            </Grid>
            <Grid item lg={8}>
              <Box
                sx={{ display: "flex", justifyContent: "center", pt: 4, pb: 4 }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: txtcolor,
                    fontWeight: 600,
                    fontFamily: "goodTime",
                  }}
                >
                  Packs
                </Typography>
              </Box>
            </Grid>
            <Divider
              variant="middle"
              sx={{ mt: 1, backgroundColor: "#AF22C8", mx: 6, width: "100%" }}
            />
            <Grid item lg={2}>
              <Box
                sx={{ display: "flex", justifyContent: "center", pr: 4 }}
              ></Box>
            </Grid>
          </Grid>
          {!loading ? (
            <>
              {!collectionData?.length ? (
                <Box sx={{ display: "flex", justifyContent: "center", pt: 6 }}>
                  <Typography
                    variant="h6"
                    sx={{ color: "white", fontFamily: "goodTime" }}
                  >
                    No Packs Found!
                  </Typography>
                </Box>
              ) : (
                <Grid
                  container
                  justifyContent={
                    collectionData?.length < 4 ? "center" : "flex-start"
                  }
                  spacing={2}
                  sx={{ pt: 4, px: 4 }}
                >
                  {collectionData &&
                    collectionData?.map((item, index) => (
                      <Grid
                        key={index}
                        item
                        lg={3}
                        sm={6}
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          py: 4,

                          pb: 2,
                        }}
                      >
                        <UserCollectionCard
                          data={item}
                          onConfirm={handleData}
                          local
                        />
                      </Grid>
                    ))}
                </Grid>
              )}
            </>
          ) : (
            <Box height={"80vh"} sx={{ pt: 10 }}>
              <CustomLoader width="15%" text={"Please wait ..."} />
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
};

export default EvmPacks;
