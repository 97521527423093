import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Divider,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import CustomLoader from "../../../components/shared/customLoader/customLoader";

import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CollectionHeader from "../../../components/layout/Shared/CollectionHeader/CollectionHeader";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  getContract,
  hexToBigInt,
  parseEventLogs,
  prepareContractCall,
  readContract,
  sendTransaction,
} from "thirdweb";
import { arbitrum } from "thirdweb/chains";
import { client } from "../../../client";
import {
  useActiveAccount,
  useSendTransaction,
  useWaitForReceipt,
} from "thirdweb/react";
import { ArbPacks } from "../../../utils/arb/arb";
import { transferEvent } from "thirdweb/extensions/erc721";
import RewardVideo from "../../campaigns/RewardVideo";
import EvmPacksModal from "../../../components/features/Evm/EvmPacksModal";
import BN from "bn.js";
import { toast } from "react-hot-toast";
import { axiosPackRequest, openseaURL } from "../../../services/api/api";
import { EvmConnectButton } from "../../../components/features/Evm/EvmConnectButton";

const containerStyle = {
  position: "relative",
  width: "100%",
  minHeight: "100vh",
  background:
    "linear-gradient(225deg, #731686 1%, transparent 10%, transparent 80%, #af22c8 100%)", // Linear gradient background
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  paddingBottom: "20px",
};
const contentStyle = {
  position: "relative",
  top: "0px",
  width: "100%",
};
function EvmPackDetail() {
  const navigate = useNavigate();
  const [noOfItems, setNoOfItems] = useState(1);

  const { text } = useSelector((state) => state.text);

  const [data, setData] = useState(null);

  const params = useParams();
  const wallet = useActiveAccount();

  useEffect(() => {
    const pack = ArbPacks.find((x) =>
      x.collections.some((y) => y.id === Number(params.id))
    );
    if (pack) {
      const collection = pack.collections.find(
        (x) => x.id === Number(params.id)
      );
      setData(collection);
    }
  }, [params.id]);

  const [packAmount, setPackAmount] = useState(1);

  useEffect(() => {
    if (data) {
      setPackAmount(data?.amount || 1);
    }
  }, [data]);

  const [contract, setContract] = useState(null);
  const [txHash, setTxHash] = useState(null);
  const [loading, setLoading] = useState(false);
  const [owner, setOwner] = useState(null);
  const [mintWallet, setMintWallet] = useState(null);

  useEffect(() => {
    if (!data?.contractAddress) {
      return;
    }
    try {
      const c = getContract({
        address: data.contractAddress,
        chain: arbitrum,
        client,
      });
      setContract(c);
      readContract({
        contract: c,
        method: "function owner() view returns (address)",
      }).then((res) => setOwner(res));
    } catch (error) {
      setContract(null);
    }
  }, [data]);

  const {
    mutate: sendTx,
    data: transactionResult,
    error,
    isPending,
  } = useSendTransaction();

  const { data: receipt } = useWaitForReceipt({
    client,
    chain: arbitrum,
    transactionHash: txHash,
  });

  useEffect(() => {
    if (transactionResult?.transactionHash) {
      setTxHash(transactionResult.transactionHash);
    }
  }, [transactionResult]);

  useEffect(() => {
    if (error) {
      if (!error?.message?.startsWith("User rejected")) {
        toast.error("Blockchain transaction failed, please try again later");
      }
      console.error(error);
      setLoading(false);
    }
  }, [error]);

  useEffect(() => {
    try {
      if (receipt) {
        const events = parseEventLogs({
          logs: receipt?.logs,
          events: [transferEvent()],
        });

        if (events.length > 0) {
          const ids = events.map((event) => Number(event.args.tokenId));
          setShowVideo(true);
          setTimeout(() => {
            setShowVideo(false, setModalOpen(true));
          }, 11000);
          setTimeout(() => {
            fetchTokens(ids)
              .then((response) => {
                setMetadata(response.data?.metadata);
                setLoading(false);
              })
              .catch((error) => {
                console.error(error);
                // Retry once if fails
                setTimeout(() => {
                  fetchTokens(ids)
                    .then((response) => {
                      setMetadata(response.data?.metadata);
                      setLoading(false);
                    })
                    .catch((error) => {
                      console.error(error);
                      setLoading(false);
                    });
                }, 3000);
              });
          }, 3000);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Error getting NFTs metadata");
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receipt]);

  const handleBuy = async (isToken = false) => {
    setLoading(true);
    try {
      const res = await axiosPackRequest("POST", "signature", {
        contract: data?.contractAddress,
        amount: noOfItems,
        packSize: packAmount,
        wallet: wallet?.address,
        isToken,
      });
      const cost = new BN(res.data.cost);
      if (isToken) {
        const tokenContract = getContract({
          address: res.data.token,
          chain: arbitrum,
          client,
        });

        const balance = await readContract({
          contract: tokenContract,
          method:
            "function balanceOf(address account) external view returns (uint256)",
          params: [wallet?.address],
        });

        if (new BN(balance).lt(cost)) {
          toast.error("Insufficient balance in your wallet");
          setLoading(false);
          return;
        }

        const allowance = await readContract({
          contract: tokenContract,
          method:
            "function allowance(address owner, address spender) external view returns (uint256)",
          params: [wallet?.address, contract.address],
        });

        if (new BN(allowance).lt(cost)) {
          const approveTx = prepareContractCall({
            contract: tokenContract,
            method:
              "function approve(address spender, uint256 amount) external",
            params: [contract.address, cost],
          });

          await sendTransaction({
            transaction: approveTx,
            account: wallet,
          });
        }
      }

      const transaction = prepareContractCall({
        contract,
        method:
          "function minWithSignature(uint256 _timestamp, uint256 _cost, address _token, uint256 mintAmount, bytes _signature) payable",
        params: [
          res.data.timestamp,
          cost,
          res.data.token,
          res.data.nftAmount,
          res.data.signature,
        ],
        value: !isToken ? hexToBigInt("0x" + cost.toString(16)) : undefined,
      });
      sendTx(transaction);
    } catch (error) {
      console.error(error);
      toast.error("Failed to initiate buy, please try again later");
      setLoading(false);
    }
  };

  const handleAdminBuy = async () => {
    if (mintWallet?.length !== 42) {
      toast.error("Invalid receiver wallet");
      return;
    }
    setLoading(true);
    try {
      const transaction = prepareContractCall({
        contract,
        method: "function mintTo(address _to, uint256 _amount) payable",
        params: [mintWallet, noOfItems * packAmount],
      });
      sendTx(transaction);
    } catch (error) {
      console.error(error);
      toast.error("Failed to initiate mint");
      setLoading(false);
    }
  };

  const fetchTokens = async (ids) => {
    return await axiosPackRequest("POST", "new-tokens", {
      contract: data?.contractAddress,
      ids,
    });
  };
  const [totalPrice, setTotalPrice] = useState(
    Number((data?.price * noOfItems).toFixed(6))
  );

  useEffect(() => {
    setTotalPrice(Number((data?.price * noOfItems).toFixed(6)));
  }, [noOfItems, data]);

  function renderContent() {
    return (
      <Grid container spacing={1}>
        <Grid
          item
          lg={4}
          md={5}
          sm={12}
          xs={12}
          // style={{
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   flexDirection: "column",
          // }}
        >
          <Box
            sx={{
              border: "2px solid #FF9A02",
              maxWidth: "16rem",
              margin: "auto",
              minHeight: 100,
              // maxHeight: 345,
              // minWidth: 100,
              // maxWidth: 325,
              p: 0.5,
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                padding: 0,
                cursor: "pointer",
                objectFit: "contain",
              }}
              src={`${data?.thumbnail}`}
              //   title="green iguana"
              alt="Pack thumbnail"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                window.open(openseaURL + contract?.address, "_blank");
              }}
              sx={{
                width: { xs: "fit-content", sm: "fit-content" },
                backgroundColor: "#AF22C8",
                fontFamily: "goodTime", // Same as above
                color: "white",
                "&:hover": {
                  backgroundColor: "#AF22C8",
                },
                "&:disabled": {
                  backgroundColor: "#AF22C8",
                },
                borderRadius: "20px",
                textTransform: "none",
                mt: { xs: 1, sm: 2 },
              }}
            >
              View NFTs
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          lg={8}
          md={7}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "center", md: "start" },
          }}
        >
          <Box>
            <Typography
              variant="body1"
              sx={{
                fontSize: 14,
                color: "white",
                textAlign: "center",
                fontFamily: "goodTime",
              }}
            >
              Description:{" "}
              <span style={{ color: "#FF9A02" }}>
                {data?.description
                  ? data?.description?.length > 50
                    ? `${data?.description?.slice(0, 50)}...`
                    : data?.description
                  : "N/A"}
              </span>
            </Typography>
          </Box>
          <Box sx={{ m: { xs: 1, sm: 2 } }}>
            <Typography
              variant="body1"
              sx={{
                fontSize: 14,
                color: "white",
                textAlign: "center",
                fontFamily: "goodTime",
                px: 4,
              }}
            >
              "Random Mint"{" "}
              <span style={{ color: "#FF9A02" }}>
                Make sure you have TLN equivelant to {totalPrice} USD in your
                wallet to purchase NFTs from this collection.
              </span>
            </Typography>
          </Box>
          <Box
            sx={{
              display: { sm: "flex" },
              justifyContent: "space-evenly",
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                // width: 400,
              }}
            >
              <>
                <Box
                  sx={
                    {
                      // display: "flex",
                      // justifyContent: "center",
                      // flexDirection: "column",
                      // alignItems: "center",
                    }
                  }
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: 14,
                      color: "white",
                      // textAlign: "center",
                      fontFamily: "goodTime",
                    }}
                  >
                    Select No. of NFTs
                  </Typography>

                  <Box
                    //onClick={handleBuyModal}

                    sx={{
                      backgroundColor: "#AF22C8",
                      fontFamily: "goodTime", // Same as above
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#AF22C8",
                      },

                      borderRadius: "20px",
                      textTransform: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "200px",
                      mt: 0.5,
                      px: 3,
                      py: 0.7,
                    }}
                  >
                    <RemoveIcon
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (noOfItems > 1) setNoOfItems(noOfItems - 1);
                      }}
                    />
                    {noOfItems}
                    <AddIcon
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (noOfItems < 5) setNoOfItems(noOfItems + 1);
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ justifyItems: "center", mt: 2 }}>
                  {!wallet ? (
                    <div style={{ marginTop: 16 }}>
                      <EvmConnectButton />
                    </div>
                  ) : (
                    <>
                      <Button
                        disabled={!wallet || !contract || loading || isPending}
                        variant="contained"
                        startIcon={<ShoppingBasketIcon />}
                        onClick={() => handleBuy(false)}
                        sx={{
                          width: { xs: "fit-content !important", sm: "100%" },
                          backgroundColor: "#AF22C8",
                          fontFamily: "goodTime", // Same as above
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#AF22C8",
                          },
                          "&:disabled": {
                            backgroundColor: "#AF22C8",
                          },
                          display: "flex",
                          borderRadius: "20px",
                          textTransform: "none",
                          mt: { xs: 1, sm: 2 },
                        }}
                      >
                        {isPending || loading || !contract ? (
                          <div
                            style={{
                              width: "300px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "25px",
                            }}
                          >
                            <CircularProgress
                              size={20}
                              sx={{ color: "white" }}
                            />
                          </div>
                        ) : (
                          `Buy for ${totalPrice} USD with ETH/Credit Card`
                        )}
                      </Button>
                      <Button
                        disabled={!wallet || !contract || loading || isPending}
                        variant="contained"
                        startIcon={<ShoppingBasketIcon />}
                        onClick={() => handleBuy(true)}
                        sx={{
                          width: { xs: "fit-content !important", sm: "100%" },
                          backgroundColor: "#AF22C8",
                          fontFamily: "goodTime", // Same as above
                          display: "flex",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#AF22C8",
                          },
                          "&:disabled": {
                            backgroundColor: "#AF22C8",
                          },
                          borderRadius: "20px",
                          textTransform: "none",
                          mt: { xs: 1, sm: 2 },
                        }}
                      >
                        {isPending || loading || !contract ? (
                          <div
                            style={{
                              width: "300px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "25px",
                            }}
                          >
                            <CircularProgress
                              size={20}
                              sx={{ color: "white" }}
                            />
                          </div>
                        ) : (
                          `Buy for ${totalPrice} USD with TLN`
                        )}
                      </Button>
                      {owner === wallet.address && (
                        <Box
                          sx={{ display: "block", mt: 6, alignItems: "center" }}
                        >
                          <TextField
                            style={{ width: "100%" }}
                            size="small"
                            placeholder="Address"
                            value={mintWallet}
                            onChange={(e) => setMintWallet(e.target.value)}
                            disabled={
                              !wallet || !contract || loading || isPending
                            }
                            InputProps={{
                              sx: {
                                color: "#D7D1D1",
                                backgroundColor: "rgba(255, 255, 255, 0.2)",
                                borderRadius: 6,
                                "& .MuiOutlinedInput-notchedOutline": {},
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  border: " 2px solid #7F6C6C",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: " 2px solid #7F6C6C",
                                  },
                                height: "35px",
                              },
                            }}
                          />
                          <Button
                            disabled={
                              !wallet || !contract || loading || isPending
                            }
                            variant="contained"
                            onClick={() => handleAdminBuy()}
                            sx={{
                              width: {
                                xs: "300px",
                                sm: "412px",
                              },
                              backgroundColor: "#AF22C8",
                              fontFamily: "goodTime", // Same as above
                              display: "flex",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "#AF22C8",
                              },
                              "&:disabled": {
                                backgroundColor: "#AF22C8",
                              },
                              borderRadius: "20px",
                              textTransform: "none",
                              mt: { xs: 1, sm: 2 },
                            }}
                          >
                            {isPending || loading || !contract ? (
                              <div
                                style={{
                                  width: "300px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "25px",
                                }}
                              >
                                <CircularProgress
                                  size={20}
                                  sx={{ color: "white" }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                Admin mint to the wallet above
                              </div>
                            )}
                          </Button>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }
  const rewardVideoSrc = false
    ? require("../../../assets/RewardBags/Potion-Burn.mp4")
    : require("../../../assets/RewardBags/Reward-Nft.mp4");
  const [showVideo, setShowVideo] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [metadata, setMetadata] = useState([]);

  // useEffect(() => {
  //   setShowVideo(true);
  //   setTimeout(() => {
  //     setShowVideo(false, setModalOpen(true));
  //   }, 11000);
  // }, []);

  if (showVideo) {
    return <RewardVideo src={rewardVideoSrc} onEnded={() => {}} />;
  }

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              {" "}
              <Box
                sx={{ display: "flex", justifyContent: "start", pt: 3, ml: 1 }}
              >
                <Button
                  size="large"
                  sx={{ color: "white", pl: 1, py: 0 }}
                  onClick={() => navigate(-1)}
                >
                  <ArrowBackIcon fontSize="medium" sx={{ color: "white" }} />
                </Button>
              </Box>
            </Grid>

            <Grid item xs={8}>
              <>
                {/* Desktop view */}
                <Box>
                  <CollectionHeader
                    Data={{
                      collection: {
                        name: data?.name,
                        thumbnail: data?.thumbnail,
                      },
                    }}
                    local
                  />
                </Box>
              </>
            </Grid>

            <Grid item xs={2}></Grid>
          </Grid>
          <Divider
            variant="middle"
            sx={{ mt: 0, backgroundColor: "#AF22C8", mx: 0, mb: 0 }}
          />
          {/* display: "flex", justifyContent: "center" */}
          {/* ml: { xs: 2, sm: 4, md: 6, lg: 8 }, */}
          <Box sx={{ p: 1, pb: 4, pt: 6 }}>
            {!false ? (
              <Box sx={{ paddingBottom: 6 }}>{renderContent()}</Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "80vh", // Set minimum height to fill the entire viewport
                }}
              >
                <CustomLoader
                  width={"60%"}
                  text={text === "" || !text ? "Please wait ..." : text}
                />{" "}
              </Box>
            )}
          </Box>
        </Box>
      </div>
      <EvmPacksModal
        openModal={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        loading={loading || isPending}
        txHash={txHash}
        metadata={metadata}
      />
    </div>
  );
}
// openModal, setOpenModal, imageUrl, nft, offersData;

export default EvmPackDetail;
