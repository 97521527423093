import SingleDruid from "../../assets/Packs/Druid/SingleCardPackDruid.png";
import DruidStarterDeck from "../../assets/Packs/Druid/DruidStarterDeck.png";
import Druid3CardPack from "../../assets/Packs/Druid/ThreeCardPackDruid.png";
import Druid5CardPack from "../../assets/Packs/Druid/FiveCardPackDruid.png";
import PirateStarterDeck from "../../assets/Packs/Pirate/PirateStarterDeck.png";
import SingleCardPackPirate from "../../assets/Packs/Pirate/SingleCardPackPirate.png";
import Pirate3CardPack from "../../assets/Packs/Pirate/ThreeCardPackPirate.png";
import Pirate5CardPack from "../../assets/Packs/Pirate/FiveCardPackPirate.png";
import GladiatorStarterDeck from "../../assets/Packs/Gladiator/GladiatorStarterDeck.png";
import SingleCardPackGladiator from "../../assets/Packs/Gladiator/SingleCardPackGladiator.png";
import Gladiator3CardPack from "../../assets/Packs/Gladiator/ThreeCardPackGladiator.png";
import Gladiator5CardPack from "../../assets/Packs/Gladiator/FiveCardPackGladiator.png";
import SingleCardPackNeutral from "../../assets/Packs/Neutral/SingleCardPackNeutral.png";
import Neutral3CardPack from "../../assets/Packs/Neutral/ThreeCardPackNeutral.png";
import Neutral5CardPack from "../../assets/Packs/Neutral/FiveCardPackNeutral.png";
import DruidDeck from "../../assets/Packs/Sliders/Druid.png";
import PirateDeck from "../../assets/Packs/Sliders/Pirate.png";
import GladiatorDeck from "../../assets/Packs/Sliders/Gladiator.png";
import NeutralDeck from "../../assets/Packs/Sliders/Neutral.png";
import AvatarsDeck from "../../assets/Packs/Sliders/Avatars.png";
import SingleAvatar from "../../assets/Packs/SingleAvatarPack.png";
import CardbacksDeck from "../../assets/Packs/Sliders/Cardback.png";
import SingleCardback from "../../assets/Packs/SingleCardbackPack.png";

export const ArbPacksStaging = [
  {
    name: "King of the Jungle Druid Cards",
    thumbnail: DruidDeck,
    collections: [
      {
        name: "Druid Starter Deck",
        thumbnail: DruidStarterDeck,
        contractAddress: "0x11b66018656b8E15707De0B021597e4EA02B6E85",
        amount: 20,
        id: 1,
        price: 40,
      },
      {
        name: "Druid Single Card",
        thumbnail: SingleDruid,
        contractAddress: "0x11b66018656b8E15707De0B021597e4EA02B6E85",
        amount: 1,
        id: 2,
        price: 2,
      },
      {
        name: "Druid 3 Card Pack",
        thumbnail: Druid3CardPack,
        contractAddress: "0x11b66018656b8E15707De0B021597e4EA02B6E85",
        amount: 3,
        id: 3,
        price: 5,
      },
      {
        name: "Druid 5 Card Pack",
        thumbnail: Druid5CardPack,
        contractAddress: "0x11b66018656b8E15707De0B021597e4EA02B6E85",
        amount: 5,
        id: 4,
        price: 8,
      },
    ],
  },
  {
    name: "King of the Jungle Pirate Cards",
    thumbnail: PirateDeck,
    collections: [
      {
        name: "Pirate Starter Deck",
        thumbnail: PirateStarterDeck,
        contractAddress: "0x0f90ab462b1b65495155381437b11d69B6BCAaF0",
        amount: 20,
        id: 5,
        price: 40,
      },
      {
        name: "Pirate Single Card",
        thumbnail: SingleCardPackPirate,
        contractAddress: "0x0f90ab462b1b65495155381437b11d69B6BCAaF0",
        amount: 1,
        id: 6,
        price: 2,
      },
      {
        name: "Pirate 3 Card Pack",
        thumbnail: Pirate3CardPack,
        contractAddress: "0x0f90ab462b1b65495155381437b11d69B6BCAaF0",
        amount: 3,
        id: 7,
        price: 5,
      },
      {
        name: "Pirate 5 Card Pack",
        thumbnail: Pirate5CardPack,
        contractAddress: "0x0f90ab462b1b65495155381437b11d69B6BCAaF0",
        amount: 5,
        id: 8,
        price: 8,
      },
    ],
  },
  {
    name: "King of the Jungle Gladiator Cards",
    thumbnail: GladiatorDeck,
    collections: [
      {
        name: "Gladiator Starter Deck",
        thumbnail: GladiatorStarterDeck,
        contractAddress: "0x3cbAbd8952926643CEF65c67480D0E4a61eE3456",
        amount: 20,
        id: 9,
        price: 40,
      },
      {
        name: "Gladiator Single Card",
        thumbnail: SingleCardPackGladiator,
        contractAddress: "0x3cbAbd8952926643CEF65c67480D0E4a61eE3456",
        amount: 1,
        id: 10,
        price: 2,
      },
      {
        name: "Gladiator 3 Card Pack",
        thumbnail: Gladiator3CardPack,
        contractAddress: "0x3cbAbd8952926643CEF65c67480D0E4a61eE3456",
        amount: 3,
        id: 11,
        price: 5,
      },
      {
        name: "Gladiator 5 Card Pack",
        thumbnail: Gladiator5CardPack,
        contractAddress: "0x3cbAbd8952926643CEF65c67480D0E4a61eE3456",
        amount: 5,
        id: 12,
        price: 8,
      },
    ],
  },
  {
    name: "King of the Jungle Neutral Cards",
    thumbnail: NeutralDeck,
    collections: [
      {
        name: "Neutral Single Card",
        thumbnail: SingleCardPackNeutral,
        contractAddress: "0xf4abfCdb47a887E6CA692C1717275e589C7Eca5f",
        amount: 1,
        id: 13,
        price: 2,
      },
      {
        name: "Neutral 3 Card Pack",
        thumbnail: Neutral3CardPack,
        contractAddress: "0xf4abfCdb47a887E6CA692C1717275e589C7Eca5f",
        amount: 3,
        id: 14,
        price: 5,
      },
      {
        name: "Neutral 5 Card Pack",
        thumbnail: Neutral5CardPack,
        contractAddress: "0xf4abfCdb47a887E6CA692C1717275e589C7Eca5f",
        amount: 5,
        id: 15,
        price: 8,
      },
    ],
  },
  {
    name: "King of the Jungle Avatars",
    thumbnail: AvatarsDeck,
    collections: [
      {
        name: "Single Avatar",
        thumbnail: SingleAvatar,
        contractAddress: "0x5fC8b3D4c9B0A0486030a410FA915a66019Beccb",
        amount: 1,
        id: 16,
        price: 5,
      },
    ],
  },
  {
    name: "King of the Jungle Card Backs",
    thumbnail: CardbacksDeck,
    collections: [
      {
        name: "Single Cardback",
        thumbnail: SingleCardback,
        contractAddress: "0x462652D04a1277E85670763701A7b8FE2817d70A",
        amount: 1,
        id: 17,
        price: 5,
      },
    ],
  },
];

export const ArbPacks = [
  {
    name: "King of the Jungle Avatars",
    thumbnail: AvatarsDeck,
    collections: [
      {
        name: "Single Avatar",
        thumbnail: SingleAvatar,
        contractAddress: "0x5c6D17508b45C84478C543Ed6B2a64B65daB357D",
        amount: 1,
        id: 16,
        price: 5,
      },
    ],
  },
  {
    name: "King of the Jungle Card Backs",
    thumbnail: CardbacksDeck,
    collections: [
      {
        name: "Single Cardback",
        thumbnail: SingleCardback,
        contractAddress: "0x9E21D9Afe3fFC8DE449A02647ecD64a89f65dd98",
        amount: 1,
        id: 17,
        price: 5,
      },
    ],
  },
  {
    name: "King of the Jungle Druid Cards",
    thumbnail: DruidDeck,
    collections: [
      {
        name: "Druid Starter Deck",
        thumbnail: DruidStarterDeck,
        contractAddress: "0xa53A9185CF94b29E4c150F21f4aa89211f6BbF3d",
        amount: 20,
        id: 1,
        price: 40,
      },
      {
        name: "Druid Single Card",
        thumbnail: SingleDruid,
        contractAddress: "0xa53A9185CF94b29E4c150F21f4aa89211f6BbF3d",
        amount: 1,
        id: 2,
        price: 2,
      },
      {
        name: "Druid 3 Card Pack",
        thumbnail: Druid3CardPack,
        contractAddress: "0xa53A9185CF94b29E4c150F21f4aa89211f6BbF3d",
        amount: 3,
        id: 3,
        price: 5,
      },
      {
        name: "Druid 5 Card Pack",
        thumbnail: Druid5CardPack,
        contractAddress: "0xa53A9185CF94b29E4c150F21f4aa89211f6BbF3d",
        amount: 5,
        id: 4,
        price: 8,
      },
    ],
  },
  {
    name: "King of the Jungle Gladiator Cards",
    thumbnail: GladiatorDeck,
    collections: [
      {
        name: "Gladiator Starter Deck",
        thumbnail: GladiatorStarterDeck,
        contractAddress: "0x0ad3f1e44bd88b3a9dd86df1a34248ed8e2a7f68",
        amount: 20,
        id: 9,
        price: 40,
      },
      {
        name: "Gladiator Single Card",
        thumbnail: SingleCardPackGladiator,
        contractAddress: "0x0ad3f1e44bd88b3a9dd86df1a34248ed8e2a7f68",
        amount: 1,
        id: 10,
        price: 2,
      },
      {
        name: "Gladiator 3 Card Pack",
        thumbnail: Gladiator3CardPack,
        contractAddress: "0x0ad3f1e44bd88b3a9dd86df1a34248ed8e2a7f68",
        amount: 3,
        id: 11,
        price: 5,
      },
      {
        name: "Gladiator 5 Card Pack",
        thumbnail: Gladiator5CardPack,
        contractAddress: "0x0ad3f1e44bd88b3a9dd86df1a34248ed8e2a7f68",
        amount: 5,
        id: 12,
        price: 8,
      },
    ],
  },
  {
    name: "King of the Jungle Neutral Cards",
    thumbnail: NeutralDeck,
    collections: [
      {
        name: "Neutral Single Card",
        thumbnail: SingleCardPackNeutral,
        contractAddress: "0xaD39C722bE94c1eB20fb70A9A28e22eba93356e9",
        amount: 1,
        id: 13,
        price: 2,
      },
      {
        name: "Neutral 3 Card Pack",
        thumbnail: Neutral3CardPack,
        contractAddress: "0xaD39C722bE94c1eB20fb70A9A28e22eba93356e9",
        amount: 3,
        id: 14,
        price: 5,
      },
      {
        name: "Neutral 5 Card Pack",
        thumbnail: Neutral5CardPack,
        contractAddress: "0xaD39C722bE94c1eB20fb70A9A28e22eba93356e9",
        amount: 5,
        id: 15,
        price: 8,
      },
    ],
  },
  {
    name: "King of the Jungle Pirate Cards",
    thumbnail: PirateDeck,
    collections: [
      {
        name: "Pirate Starter Deck",
        thumbnail: PirateStarterDeck,
        contractAddress: "0x792741a8995c4e9Aee1D5e92AA1513e7C5EB3fEE",
        amount: 20,
        id: 5,
        price: 40,
      },
      {
        name: "Pirate Single Card",
        thumbnail: SingleCardPackPirate,
        contractAddress: "0x792741a8995c4e9Aee1D5e92AA1513e7C5EB3fEE",
        amount: 1,
        id: 6,
        price: 2,
      },
      {
        name: "Pirate 3 Card Pack",
        thumbnail: Pirate3CardPack,
        contractAddress: "0x792741a8995c4e9Aee1D5e92AA1513e7C5EB3fEE",
        amount: 3,
        id: 7,
        price: 5,
      },
      {
        name: "Pirate 5 Card Pack",
        thumbnail: Pirate5CardPack,
        contractAddress: "0x792741a8995c4e9Aee1D5e92AA1513e7C5EB3fEE",
        amount: 5,
        id: 8,
        price: 8,
      },
    ],
  },
];
